/* Hide scrollbar by default */
/* .scroll-container::-webkit-scrollbar {
    display: none;
} */

/* Show scrollbar when hovering over the scroll container */
/* .scroll-container:hover::-webkit-scrollbar {
    display: block;
} */


.scroll-container::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to 0 */
    display: none; /* Hide the scrollbar */
  }