@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Nunito Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #fdf2ec;
}

/* ===== Scrollbar Customization CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #febc95 #ebebeb;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #ebebeb;
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: #febc95;
    border-radius: 10px;
    border: none;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #fe8740;
}   
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}